*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
}

body {
    height: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}